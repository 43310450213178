import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Spacer from '../components/spacer';
import MagazinePreview from '../components/magazinePreview';
import { graphql } from "gatsby"

const Magazine = ({ location, data }) => {
    const page = data.datoCmsTapestryLandingPage;

    const contactInfo = { email: page.email, insta: page.instagram, facebook: page.facebook }

    return (
        <Layout  >
            <SEO title="Magazine" />
            <Header location={location} />
            <Spacer />
            <MagazinePreview />
            <Spacer />
            <Footer contactInfo={contactInfo} info={page} />
        </Layout>
    )
}



export default Magazine

export const query = graphql`
	query MagazinePage{
		datoCmsTapestryLandingPage {
			ctaImage{
				gatsbyImageData
			}
			mobileIntroductionImage{
				gatsbyImageData
			}
			designQuoteAttribution
			designQuote
			carouselImages {
				gatsbyImageData
				url
			}
			gallery {
     			gatsbyImageData
   			 }
			designImage {
				gatsbyImageData
			}
			introductionText
			introductionImage {
				gatsbyImageData
			}   
			magazineName
            magazineImage {
                gatsbyImageData
            }
			designSubtitle
			splitSectionParagraph
			splitSectionImage {
				gatsbyImageData
			}
			splitSectionSubtitle
			address
			email
			instagram
			facebook 
			people {
				email
				name
				phone
			}
		}
	 
}
`