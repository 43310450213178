import React from 'react';

const MagazinePreview = () => {
    return (
        <div className="p40 ">
            <div className="max-1200 ma ">
                <div className='bg-light ' >
                <div style={{position:'relative',paddingTop:`max(60%,326px)`,height:0,width:`100%`,top:0}}><iframe title="magazine" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox" allowfullscreen="true" style={{position:`absolute`,border:`none`,width:`100%`,height:`100%`,left:0,right:0,top:0,bottom:0}} src="https://e.issuu.com/embed.html?backgroundColor=%232D443E&backgroundColorFullscreen=%2312312B&d=tapestry_exhibit_issue_01&u=sieragroup"></iframe></div>
                </div>
            </div>
        </div>
    )
}

export default MagazinePreview